/* WA Style Variables */
@import '../colors';

$primary: #174A7C;
$secondary: #FA9800;
$tertiary: #3A95D2;
$light: #e6e6e6;
$danger: darken(#FF7D7D, 33%);

$font-family-base: 'Open Sans', sans-serif;


/* Primary Page-Styling Variables */
$body-bg: #495058;
$body-color: #222;

$footer-bg: $primary;

$tooltip-icon-color: $primary;

/* Proccess Page Styling */
$process-page-themes: (
  "claim": $primary,
  "holder": $primary
);


$state-theme-colors: (
  "tertiary": $tertiary,
);
$theme-colors: map-merge($theme-colors, $state-theme-colors);


$dropdown-bg: #174A7C;
$dropdown-hover-bg: #11365A;
$dropdown-divider-bg: #777;

$dropdown-link-color: #ffffff;
$dropdown-link-hover-color: #ffffff;
$dropdown-link-hover-bg: #11365A;

$mobile-navbar-bg: #495058;
$static-page-header-color: $primary;
$static-page-subtitle-color: #333;

$btn-upload-color: $secondary;
$btn-upload-text-color: #000000;

/* Image Size & Image Source Variables */
$banner-image-url: url('/images/main_banner.jpg');

$claim-header-logo-url: url('/images/main_logo.png');
$claim-header-logo-height: 86px;
$claim-header-logo-width: 209px;

$holder-header-logo-url: $claim-header-logo-url;
$holder-header-logo-height: $claim-header-logo-height;
$holder-header-logo-width: $claim-header-logo-width;

$home-logo-url: url('/images/main_logo.png');
$home-logo-width: 209px;
$home-logo-height: 86px;
$home-logo-resize-value-map: (width: $home-logo-width,
  height: $home-logo-height,
  background-image: url('/images/main_logo.png'));

$treasurer-logo-url: $home-logo-url;
$treasurer-logo-width: $home-logo-width;
$treasurer-logo-height: $home-logo-height;
$treasurer-logo-resize-value-map: $home-logo-resize-value-map;

$footer-logo-url: url('/images/state_seal.png');
$footer-logo-width: 150px;
$footer-logo-height: 150px;

$claimform-image-url: url('/images/sample_claim_form.png');

$icon-email-url: none;
$icon-facebook-url: url('/images/facebook.png');
$icon-instagram-url: none;
$icon-twitter-url: url('/images/twitter.png');
$icon-youtube-url: url('/images/youtube.png');
$icon-linkedin-url: url('/images/linkedin.png');

$social-icon-width: 50px;
$social-icon-height: 50px;

$btn-pop-bg: $secondary;
$link-color: #0000FF;

$ticker-bg: #8A8A99;

#back-to-claim-info-btn {
  .btn:hover {
    color: $white !important;
  }
}

#holder-upload {
  .btn {
    color: $white !important;
    background: $primary !important;
    border-color: $primary !important;

    &:hover {
      background-color: darken($primary, 10%) !important;
      border: 1px solid darken($primary, 10%) !important;
    }
  }
}

#holder-summary {
  .btn {
    color: $white !important;
    background: $primary !important;
    border-color: $primary !important;

    &:hover {
      background-color: darken($primary, 10%) !important;
      border: 1px solid darken($primary, 10%) !important;
    }
  }
}

.bg-grey {
  background: $gray-500 !important;
}

.btn-action {
  color: $black !important;
}

.btn-pop {
  color: $black !important;
}

.icon-survey {
  width: 155px;
  position: relative;
  margin-top: 15px;
}

.bg-light-yellow {
  background-color: #fcc65d;
}

.wa-tablet-view {
  @media (max-width: 1020px) {
    display: none;
  }
}

.banner-text {
  h1 {
    font-weight: normal;
    font-size: 40px;
  }

  h4 {
    font-weight: normal;
  }

  margin-left: 280px;
  margin-top: 20px;
  align-self: flex-end;
}
